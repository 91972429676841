import React from "react";

class Customs extends React.Component {
  state = {
    projects: [],
    requests: []
  };
  render() {
    return <div />;
  }
}

export default Customs;
